@font-face {
    font-family: 'Mix Pudgy Panda Mix Dingbats';
    src: url('./assets/fonts/pudgy-panda/MixPudgyPandaMixDingbats.woff2') format('woff2'),
    url('./assets/fonts/pudgy-panda/MixPudgyPandaMixDingbats.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mix Pudgy Panda';
    src: url('./assets/fonts/pudgy-panda/MixPudgyPandaRegular.woff2') format('woff2'),
    url('./assets/fonts/pudgy-panda/MixPudgyPandaRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mix Pudgy Panda';
    src: url('./assets/fonts/pudgy-panda/MixPudgyPandaRegular.woff2') format('woff2'),
    url('./assets/fonts/pudgy-panda/MixPudgyPandaRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mix Pudgy Panda Mix Dingbats';
    src: url('./assets/fonts/pudgy-panda/MixPudgyPandaMixDingbats.woff2') format('woff2'),
    url('./assets/fonts/pudgy-panda/MixPudgyPandaMixDingbats.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mix Pudgy Panda Mix Dingbats';
    src: url('./assets/fonts/pudgy-panda/MixPudgyPandaMixDingbats.woff2') format('woff2'),
    url('./assets/fonts/pudgy-panda/MixPudgyPandaMixDingbats.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mix Pudgy Panda';
    src: url('./assets/fonts/pudgy-panda/MixPudgyPandaRegular.woff2') format('woff2'),
    url('./assets/fonts/pudgy-panda/MixPudgyPandaRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-Bold.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso Extra';
    src: url('./assets/fonts/meloso/Meloso-ExtraLightItalic.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-ExtraLightItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-Medium.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso Extra';
    src: url('./assets/fonts/meloso/Meloso-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso Extra';
    src: url('./assets/fonts/meloso/Meloso-ExtraBoldItalic.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso Variable';
    src: url('./assets/fonts/meloso/MelosoVariable-Regular.woff2') format('woff2'),
    url('./assets/fonts/meloso/MelosoVariable-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso Extra';
    src: url('./assets/fonts/meloso/Meloso-ExtraLight.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-ExtraLight.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-Regular.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-BoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-Regular.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-Italic.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Meloso';
    src: url('./assets/fonts/meloso/Meloso-Light.woff2') format('woff2'),
    url('./assets/fonts/meloso/Meloso-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}