.react-select-container {
    margin-top: 15px;
    margin-bottom: 20px;
}

.react-select-container .react-select__control {
    position: relative;
    display: block;
    width: 100%;
    padding: 8px 25px;
    margin-right: 30px;
    font-size: 18px;
    font-family: inherit;
    line-height: 1.42857143;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid rgba(135, 138, 62, 0.5);
    border-radius: 40px;
}

.react-select-container .react-select__indicator-separator {
    display: none;
}

.react-select-container .react-select__indicator {
    position: relative;
    width: 15px;
    height: 15px;
    padding: 0;
}

.react-select-container .react-select__dropdown-indicator:before {
    position: absolute;
    content: '';
    background: url("/src/assets/icons/down-arrow.svg");
    width: 15px;
    height: 15px;
}

.react-select__indicator svg {
    display: none;
}

.react-select-container .react-select__indicators {
    position: absolute;
    right: 11px;
    top: 16px;
}

.react-select-container .react-select__value-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    justify-content: start;
}


.react-select-container .react-select__multi-value {
    background: transparent;
}

.react-select-container .react-select__option {
    background: transparent;
    padding: 15px 20px;
    border-top: 1px solid rgba(135, 138, 62, 0.5);
}

.react-select-container .react-select__option:first-child {
    border-top: none;
}

.react-select-container .react-select__menu {
    border: 1px solid rgba(135, 138, 62, 0.5);
    border-radius: 40px;
    overflow: hidden;
}

.react-select-container .css-mohuvp-dummyInput-DummyInput {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    position: absolute;
}

.react-select__multi-value__remove {
    position: relative;
    width: 20px;
}

.react-select-container .react-select__multi-value__remove:hover {
    background-color: transparent;
    cursor: pointer;
}

.react-select__multi-value__remove:before {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    background-image: url('../../assets/icons/close-2.svg');
    background-size: contain;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
}

.react-select__multi-value__remove svg {
    display: none;
}